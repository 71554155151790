<template>
  <svg
    width="64"
    height="64"
    viewBox="0 0 64 64"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M45.3333 8H18.6667C12.7756 8 8 12.7756 8 18.6667V45.3333C8 51.2244 12.7756 56 18.6667 56H45.3333C51.2244 56 56 51.2244 56 45.3333V18.6667C56 12.7756 51.2244 8 45.3333 8Z"
      stroke="#4874E6"
      stroke-width="5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M24 32.0003L30 37.3337L40 26.667"
      stroke="#4874E6"
      stroke-width="5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>

<script>
export default {
  name: "PaySuccessIcon",
};
</script>

<style scoped></style>
